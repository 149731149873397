import React from 'react';
import mobiscroll from "@mobiscroll/react";
import { withRouter } from 'react-router-dom';
import PayInvoiceByCard from "../PayInvoiceByCard";
import {logger, updateObject, isNumeric, jsDateToday, hashCode, isIframe} from "../../../lib/Utility";
import {netFirebase} from "../../../lib/Firebase";
import Header from "../../../components/Header";

const termsUrl = process.env.REACT_APP_APP_TERMS_URL !== undefined && process.env.REACT_APP_APP_TERMS_URL !== null && process.env.REACT_APP_APP_TERMS_URL !== "" ? process.env.REACT_APP_APP_TERMS_URL : "/#/terms";
const privacyUrl = process.env.REACT_APP_APP_PRIVACY_URL !== undefined && process.env.REACT_APP_APP_PRIVACY_URL !== null && process.env.REACT_APP_APP_PRIVACY_URL !== "" ? process.env.REACT_APP_APP_PRIVACY_URL : "/#/privacy";

let pinApi = null;

class PayInvoiceByCardPinPayments extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            paying: false,
            paid: false,
            // data to be serialised and sent to payment processor
            data: {
                cvv: '',
                return_path: window.location.href
            },
            // additional meta data, eg. client code
            extra: {
            },
            payment: null
        };

        this.handlePayInvoiceFormStateChange = this.handlePayInvoiceFormStateChange.bind(this);

        /*eslint-disable no-undef*/
        //logger(typeof Pin);
        if (typeof Pin === 'object') {
            pinApi = new Pin.Api(this.props.merchant.providerUser, this.props.merchant.production ? 'live' : 'test');
            //logger(pinApi);
        } else {
            logger('Pin not loaded')
        }
        /*eslint-enable no-undef*/

    }

    convertDecimalAmount = (amount) => {
        if (amount !== undefined && isNumeric(amount)) {
            return Math.round(amount * 100);
        }
        return 0;
    }

    extractExpiryMonth = (expiry) => {
        if (expiry !== undefined && expiry.length >= 2) {
            return expiry.substring(0, 2);
        }
        return '';
    }

    extractExpiryYear = (expiry) => {
        if (expiry !== undefined && expiry.length >= 4) {
            if (expiry.length >= 5) {
                return "20"+expiry.substring(3, 5);
            } else if (!isNaN(expiry.substring(2, 4))) {
                return "20" + expiry.substring(2, 4);
            }
        }
        return '';
    }

    handlePayInvoiceFormStateChange = (_State) => {
        //logger(_State);
        const dataState = updateObject(this.state.data, {
            email: _State.email,
            amount: this.convertDecimalAmount(_State.amount),
            surchargeAmount: this.convertDecimalAmount(_State.surchargeAmount),
            totalAmount: this.convertDecimalAmount(_State.totalAmount),
            currency: _State.currency,
            reference: '',
            card_holder: _State.card.name,
            card_number: _State.card.number,
            expiry_month: this.extractExpiryMonth(_State.card.expiry),
            expiry_year: this.extractExpiryYear(_State.card.expiry),
            cvv: _State.card.cvc,
            card_token: ''
        });
        const extraState = updateObject(this.state.extra, {
            client: process.env.REACT_APP_CLIENT_REFERENCE_ENABLED && process.env.REACT_APP_CLIENT_REFERENCE_ENABLED === 'true' ? _State.client : "",
            invnum: process.env.REACT_APP_INVOICE_REFERENCE_ENABLED && process.env.REACT_APP_INVOICE_REFERENCE_ENABLED === 'true' ? _State.invnum : ""
        });
        //logger(dataState);
        const updatedState = updateObject(this.state, {
            data: dataState,
            extra: extraState
        });
        this.setState(updatedState);
    }

    setPayingStatus = (paying, callback = null) => {
        const updatedState = updateObject(this.state, {
            paying: paying
        });
        if (callback !== null) {
            this.setState(updatedState, callback);
        } else {
            this.setState(updatedState);
        }
    }

    createCardTokenRequest = () => {

        const card = {
            number:           this.state.data.card_number,
            name:             this.state.data.card_holder,
            expiry_month:     this.state.data.expiry_month,
            expiry_year:      this.state.data.expiry_year,
            cvc:              this.state.data.cvv,
            address_line1:    this.state.data.billing_address1 ? this.state.data.billing_address1 : 'Unknown',
            // address_line2:    this.state.data.billing_address2,
            address_city:     this.state.data.billing_city ? this.state.data.billing_city : 'Unknown',
            // address_state:    this.state.data.billing_state,
            // address_postcode: this.state.data.billing_postcode,
            address_country:  this.state.data.billing_country ? this.state.data.billing_country : 'AU'
        };

        //logger(card);

        if (pinApi !== null) {
            // check state for existing card token?
            //this.state.data.card_token
            pinApi.createCardToken(card).then(
                this.createCardTokenSuccessCallback,
                this.createCardTokenErrorCallback
            ).done();
        } else {
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: "Error initialising payment. Reload the page and try again.", color: 'danger', display: 'center'});
            this.setPayingStatus(false);
        }
    }

    createCardTokenSuccessCallback = (card) => {
        logger('createCardTokenSuccessCallback data');
        logger(card);

        // add card.token to state?
        this.chargeCardTokenRequest(card.token)
    }

    createCardTokenErrorCallback = (response) => {
        logger('createCardTokenErrorCallback data');
        logger(response);

        let error_msg = response.error_description;
        if (response.messages && response.messages.length > 0) {
            error_msg = response.messages[0].message;
        }

        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: error_msg, color: 'danger', display: 'center'});
        this.setPayingStatus(false);
    }

    chargeCardTokenRequest = (card_token) => {

        //const card_token = this.state.data.card_token;
        let reference = this.state.data.reference;
        const client = this.state.extra.hasOwnProperty('client') ? this.state.extra.client : "";
        const invnum = this.state.extra.hasOwnProperty('invnum') ? this.state.extra.invnum : "";

        if (reference === "") {
            if (client !== "") {
                reference = client+"-";
            } else {
                reference = process.env.REACT_APP_MERCHANT_ID+"-";
            }
            if (invnum !== "") {
                reference = reference + invnum;
            } else {
                const uniqRef = hashCode(jsDateToday().getTime()+this.state.data.card_holder+this.state.data.expiry_month+this.state.data.expiry_year+this.state.data.totalAmount);
                reference = reference + Math.abs(uniqRef);
            }
        }

        const chargeCardTokenRequestPinPayments = netFirebase._firebase_app.functions(process.env.REACT_APP_FIREBASE_REGION).httpsCallable('chargeCardTokenRequestPinPayments');
        chargeCardTokenRequestPinPayments({
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            merchantToken: process.env.REACT_APP_MERCHANT_TOKEN,
            cardToken: card_token,
            reference: reference,
            email: this.state.data.email,
            amount: this.state.data.totalAmount,
            currency: this.state.data.currency,
            returnPath: this.state.data.return_path,
        }).then((result) => {
            //logger(result);
            if (result.data.status === "OK") {
                // successfully charged card
                mobiscroll.notification.dismiss();
                mobiscroll.toast({message: 'Thank you for your payment', color: 'success', display: 'center'});

                const dataState = updateObject(this.state.data, {
                    card_token: card_token,
                    reference: reference
                });
                const updatedState = updateObject(this.state, {
                    //paying: false,
                    paid: true,
                    payment: result.data.data,
                    data: dataState,
                });
                this.setState(updatedState);
            } else {
                // result.data.status === "ERR"
                let message = result.data.data.error;
                mobiscroll.notification.dismiss();
                mobiscroll.toast({message: message, color: 'danger', display: 'center'});
                this.setPayingStatus(false);
            }
        }).catch((error) => {
            logger(error)
            //let code = error.code;
            let message = error.message;
            //let details = error.details;
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: message, color: 'danger', display: 'center'});
            this.setPayingStatus(false);
        });

    }

    payInvoice = () => {

        //logger(this.state);
        //logger(this.state.data);
        let isValid = true;

        if (isValid && process.env.REACT_APP_CLIENT_REFERENCE_ENABLED && process.env.REACT_APP_CLIENT_REFERENCE_ENABLED === 'true' && this.state.extra.client  === "") {
            isValid = false;
            mobiscroll.toast({message: "Enter your "+process.env.REACT_APP_CLIENT_REFERENCE_LABEL.toLowerCase(), color: 'danger', display: 'center'});
        }
        if (isValid && process.env.REACT_APP_INVOICE_REFERENCE_ENABLED && process.env.REACT_APP_INVOICE_REFERENCE_ENABLED === 'true' && this.state.extra.invnum === "") {
            isValid = false;
            mobiscroll.toast({message: "Enter your "+process.env.REACT_APP_INVOICE_REFERENCE_LABEL.toLowerCase(), color: 'danger', display: 'center'});
        }
        if (isValid && this.state.data.amount <= 0) {
            isValid = false;
            mobiscroll.toast({message: "Enter a valid payment amount", color: 'danger', display: 'center'});
        }
        if (isValid && (!this.state.data.hasOwnProperty('card_number') || this.state.data.card_number === "")) {
            isValid = false;
            mobiscroll.toast({message: "Enter a valid card number", color: 'danger', display: 'center'});
        }
        if (isValid && (!this.state.data.hasOwnProperty('card_holder') || this.state.data.card_holder === "")) {
            isValid = false;
            mobiscroll.toast({message: "Enter the cardholder name as it appears on the card", color: 'danger', display: 'center'});
        }
        if (isValid && (this.state.data.expiry_month === "" || this.state.data.expiry_year === "")) {
            isValid = false;
            mobiscroll.toast({message: "Enter the card expiry month and year", color: 'danger', display: 'center'});
        }
        if (isValid && this.state.data.cvv === "") {
            isValid = false;
            mobiscroll.toast({message: "Enter the card security code", color: 'danger', display: 'center'});
        }
        if (isValid && this.props.merchant.emailAddressRequired && this.state.data.email === "") {
            isValid = false;
            mobiscroll.toast({message: "Enter the cardholder email address", color: 'danger', display: 'center'});
        }

        if (isValid) {
            //mobiscroll.notification.dismiss();
            mobiscroll.toast({
                message: "Processing your payment...<br/>Please wait for confirmation",
                duration: 30000,
                display: 'center',
                color: 'info'
            });
            this.setPayingStatus(true, this.createCardTokenRequest);
        }

    };

    renderSuccessMessage = () => {
        return <mobiscroll.Note color="success">
            <div className="mbsc-align-center">
                <p>Your payment has been successful, thank you.</p>
                <p>Payment will appear on your card as <b>{process.env.REACT_APP_MERCHANT_OF_RECORD}</b></p>
                <p>Receipt: {this.state.payment.id}</p>
            </div>
        </mobiscroll.Note>
    }

    render = () => {

        const inputStyle = "underline"; //mobiscroll.platform.name === "ios" ? "underline" : "outline";
        const labelStyle = "stacked";

        return (
            <mobiscroll.Form
                className="net-form-width-lg"
                inputStyle={inputStyle}
                labelStyle={labelStyle}
            >

                <Header />

                {this.state.paid ?
                    this.renderSuccessMessage()
                    :
                    <React.Fragment>
                        <PayInvoiceByCard
                            inputStyle={inputStyle}
                            labelStyle={labelStyle}
                            merchant={this.props.merchant}
                            handlePayInvoiceFormStateChange={this.handlePayInvoiceFormStateChange}
                        />

                        <div className="mbsc-grid">
                            <div className="mbsc-row">
                                <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4">
                                    <div className="mbsc-btn-group-block">
                                        <mobiscroll.Button onClick={(e) => {
                                                e.preventDefault();
                                                this.payInvoice();
                                            }} disabled={this.state.paying} color="success" style={{color: '#fff'}}>Pay Now</mobiscroll.Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }

                <div className="mbsc-align-center mbsc-txt-s">
                    <p>Payment will appear on your card as <b>{process.env.REACT_APP_MERCHANT_OF_RECORD}</b></p>
                    {!isIframe() ?
                    <p><a href={termsUrl}>Terms &amp; Refund Policy</a> | <a href={privacyUrl}>Privacy Policy</a></p>
                        : null }
                    <p>Secure payments by <a href="https://www.netpayments.com.au" target="_blank" rel="noreferrer">Net Payments</a></p>
                    {this.props.merchant.cards.map((card, idx) => <img key={card} src={"./img/"+card+".png"} alt="" style={{maxHeight: "45px"}} />)}
                </div>
            </mobiscroll.Form>
        );
    }
}

export default withRouter(PayInvoiceByCardPinPayments);