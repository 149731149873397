import React from 'react';
import mobiscroll from "@mobiscroll/react";
import { withRouter } from 'react-router-dom';
import PayInvoiceByCard from "../PayInvoiceByCard";
import $ from "jquery";
import queryString from "query-string";
import {logger, updateObject, isNumeric, jsDateToday, hashCode, isIframe} from "../../../lib/Utility";
//import 'firebase/functions';
import {netFirebase} from "../../../lib/Firebase";
import Header from "../../../components/Header";

const termsUrl = process.env.REACT_APP_APP_TERMS_URL !== undefined && process.env.REACT_APP_APP_TERMS_URL !== null && process.env.REACT_APP_APP_TERMS_URL !== "" ? process.env.REACT_APP_APP_TERMS_URL : "/#/terms";
const privacyUrl = process.env.REACT_APP_APP_PRIVACY_URL !== undefined && process.env.REACT_APP_APP_PRIVACY_URL !== null && process.env.REACT_APP_APP_PRIVACY_URL !== "" ? process.env.REACT_APP_APP_PRIVACY_URL : "/#/privacy";

class PayInvoiceByCardFatZebra extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            paying: false,
            paid: false,
            // data to be serialised and sent to payment processor
            data: {
                cvv: '',
                return_path: window.location.href,
                verification: '',
            },
            // additional meta data, eg. client code
            extra: {
            },
            payment: null
        };

        this.handlePayInvoiceFormStateChange = this.handlePayInvoiceFormStateChange.bind(this);

    }

    convertDecimalAmount = (amount) => {
        if (amount !== undefined && isNumeric(amount)) {
            return Math.round(amount * 100);
        }
        return 0;
    }

    extractExpiryMonth = (expiry) => {
        if (expiry !== undefined && expiry.length >= 2) {
            return expiry.substring(0, 2);
        }
        return '';
    }

    extractExpiryYear = (expiry) => {
        if (expiry !== undefined && expiry.length >= 4) {
            if (expiry.length >= 5) {
                return "20"+expiry.substring(3, 5);
            } else if (!isNaN(expiry.substring(2, 4))) {
                return "20" + expiry.substring(2, 4);
            }
        }
        return '';
    }

    handlePayInvoiceFormStateChange = (_State) => {
        //logger(_State);
        const dataState = updateObject(this.state.data, {
            // cannot change field names or add more for fat zebra, otherwise get error code 99
            //email: _State.email,
            //amount: this.convertDecimalAmount(_State.amount),
            amount: this.convertDecimalAmount(_State.totalAmount),
            //surchargeAmount: this.convertDecimalAmount(_State.surchargeAmount),
            //totalAmount: this.convertDecimalAmount(_State.totalAmount),
            currency: _State.currency,
            reference: '',
            card_holder: _State.card.name,
            card_number: _State.card.number,
            expiry_month: this.extractExpiryMonth(_State.card.expiry),
            expiry_year: this.extractExpiryYear(_State.card.expiry),
            cvv: _State.card.cvc,
            verification: ''
        });
        const extraState = updateObject(this.state.extra, {
            client: process.env.REACT_APP_CLIENT_REFERENCE_ENABLED && process.env.REACT_APP_CLIENT_REFERENCE_ENABLED === 'true' ? _State.client : "",
            invnum: process.env.REACT_APP_INVOICE_REFERENCE_ENABLED && process.env.REACT_APP_INVOICE_REFERENCE_ENABLED === 'true' ? _State.invnum : "",
            email: _State.email,
        });
        //logger(dataState);
        const updatedState = updateObject(this.state, {
            data: dataState,
            extra: extraState
        });
        this.setState(updatedState);
    }

    setPayingStatus = (paying, callback = null) => {
        const updatedState = updateObject(this.state, {
            paying: paying
        });
        if (callback !== null) {
            this.setState(updatedState, callback);
        } else {
            this.setState(updatedState);
        }
    }

    verifyPaymentRequest = () => {

        let reference = this.state.data.reference;
        const client = this.state.extra.hasOwnProperty('client') ? this.state.extra.client : "";
        const invnum = this.state.extra.hasOwnProperty('invnum') ? this.state.extra.invnum : "";
        if (reference === "") {
            if (client !== "") {
                reference = client+"-";
            } else {
                reference = process.env.REACT_APP_MERCHANT_ID+"-";
            }
            if (invnum !== "") {
                reference = reference + invnum;
            } else {
                const uniqRef = hashCode(jsDateToday().getTime()+this.state.data.card_holder+this.state.data.expiry_month+this.state.data.expiry_year+this.state.data.totalAmount);
                reference = reference + Math.abs(uniqRef);
            }
        }

        const verifyPaymentRequestFatZebra = netFirebase._firebase_app.functions(process.env.REACT_APP_FIREBASE_REGION).httpsCallable('verifyPaymentRequestFatZebra');
        verifyPaymentRequestFatZebra({
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            merchantToken: process.env.REACT_APP_MERCHANT_TOKEN,
            reference: reference,
            email: this.state.extra.email,
            amount: this.state.data.amount,
            currency: this.state.data.currency,
            returnPath: this.state.data.return_path,
        }).then((result) => {
            logger(result);
            if (result.data.status === "OK") {
                // set this.state.data.verification
                const dataState = updateObject(this.state.data, {
                    reference: reference,
                    verification: result.data.data.verification
                });
                const updatedState = updateObject(this.state, {
                    data: dataState,
                });
                this.setState(updatedState, this.makePaymentRequest);
            } else {
                // result.data.status === "ERR"
                let message = result.data.error;
                mobiscroll.notification.dismiss();
                mobiscroll.toast({message: message, color: 'danger', display: 'center'});
                this.setPayingStatus(false);
            }
        }).catch((error) => {
            logger(error)
            //let code = error.code;
            let message = error.message;
            //let details = error.details;
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: message, color: 'danger', display: 'center'});
            this.setPayingStatus(false);
        });

    }

    makePaymentRequest = () => {

        const url = this.props.merchant.production ?
            "https://gateway.pmnts.io/v2/purchases/direct/"+this.props.merchant.providerUser+".json" :
            "https://gateway.pmnts-sandbox.io/v2/purchases/direct/"+this.props.merchant.providerUser+".json"

        //logger(this.state.data);

        $.ajax({
            type: "GET",
            url: url,
            data: queryString.stringify(this.state.data),
            contentType: "text/javascript",
            dataType: "jsonp",
            error: (e) => {
                // Handle errors here - all non HTTP 2xx errors, such as HTTP 500, 403, 401 etc
                // (very unlikely, but HTTP 500 or HTTP 502 is the most likely to happen)
                logger(e.message);
                mobiscroll.notification.dismiss();
                mobiscroll.toast({message: e.message, color: 'danger', display: 'center'});
                this.setPayingStatus(false);
            }
        }).then((res) => {
            this.makePaymentRequestCallback(res);
        });

    }

    makePaymentRequestCallback = (data) => {
        logger('makePaymentRequestCallback data');
        logger(data);

        if (data.r === 1) {
            // successfully tokenized
            //{"r":1,"successful":true,"amount":1000,"currency":"AUD","id":"40446-P-689DI0AE","v":"96c28d3203c9928c0338c869a1bac2f4","token":"fu9mo8jka0utx2bsy8yj","message":"Approved","card_holder":"TEST CUSTOMER","card_number":"512345XXXXXX2346","card_expiry":"12/2022","reference":"DirectTest1B","settlement_date":"2020-12-22","s":-1}
            //logger("Token: " + data.token);
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: 'Thank you for your payment', color: 'success'});

            const updatedState = updateObject(this.state, {
                //paying: false,
                paid: true,
                payment: data
            });
            this.setState(updatedState);

        } else {
            let error_message = "Unknown Error ("+data.r+"). Please Try Again.";
            if (parseInt(data.r, 10) === 96 || parseInt(data.r, 10) === 97) {
                //   - 96 {"r":96,"existing":{"r":1,"id":"40446-P-QYGKS5BU","successful":true,"amount":1000,"currency":"AUD","token":"fu9mo8jka0utx2bsy8yj","message":"Approved","card_holder":"TEST CUSTOMER","card_number":"512345XXXXXX2346","card_expiry":"12/2022","reference":"DirectTest2","v":"ae53b9fb4ef5c29c650df5ab85e48a07"},"errors[]":["Reference is already taken"]}
                //   - 97 Failed, validation error
                error_message = "Validation error:\r\n";
                $(data["errors[]"]).each(function () {
                    error_message += "<br/>- " + this + "\r\n";
                });
            } else if (parseInt(data.r, 10) === 99) {
                //   - 99 (validation error - e.g. form has been tampered with)
                error_message = "Error 99 processing this request. Contact us for assistance.";
                //logger(this.state.data);
            } else {
                // Remaining errors could be:
                //   - 95 (merchant not found - check your username)
                //   - 999 (gateway error - merchant should contact the Gateway if this error persists.)
            }
            logger(error_message);
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: error_message, color: 'danger', display: 'center'});
            this.setPayingStatus(false);
        }

    }

    payInvoice = () => {

        logger(this.state);
        //logger(this.state.data);
        let isValid = true;

        if (isValid && process.env.REACT_APP_CLIENT_REFERENCE_ENABLED && process.env.REACT_APP_CLIENT_REFERENCE_ENABLED === 'true' && this.state.extra.client  === "") {
            isValid = false;
            mobiscroll.toast({message: "Enter your "+process.env.REACT_APP_CLIENT_REFERENCE_LABEL.toLowerCase(), color: 'danger', display: 'center'});
        }
        if (isValid && process.env.REACT_APP_INVOICE_REFERENCE_ENABLED && process.env.REACT_APP_INVOICE_REFERENCE_ENABLED === 'true' && this.state.extra.invnum === "") {
            isValid = false;
            mobiscroll.toast({message: "Enter your "+process.env.REACT_APP_INVOICE_REFERENCE_LABEL.toLowerCase(), color: 'danger', display: 'center'});
        }
        if (isValid && this.state.data.amount <= 0) {
            isValid = false;
            mobiscroll.toast({message: "Enter a valid payment amount", color: 'danger', display: 'center'});
        }
        if (isValid && (!this.state.data.hasOwnProperty('card_number') || this.state.data.card_number === "")) {
            isValid = false;
            mobiscroll.toast({message: "Enter a valid card number", color: 'danger', display: 'center'});
        }
        if (isValid && (!this.state.data.hasOwnProperty('card_holder') || this.state.data.card_holder === "")) {
            isValid = false;
            mobiscroll.toast({message: "Enter the cardholder name as it appears on the card", color: 'danger', display: 'center'});
        }
        if (isValid && (this.state.data.expiry_month === "" || this.state.data.expiry_year === "")) {
            isValid = false;
            mobiscroll.toast({message: "Enter the card expiry month and year", color: 'danger', display: 'center'});
        }
        if (isValid && this.state.data.cvv === "") {
            isValid = false;
            mobiscroll.toast({message: "Enter the card security code", color: 'danger', display: 'center'});
        }
        if (isValid && this.props.merchant.emailAddressRequired && this.state.extra.email === "") {
            isValid = false;
            mobiscroll.toast({message: "Enter the cardholder email address", color: 'danger', display: 'center'});
        }

        if (isValid) {
            //mobiscroll.notification.dismiss();
            mobiscroll.toast({
                message: "Processing your payment...<br/>Please wait for confirmation",
                duration: 30000,
                display: 'center',
                color: 'info'
            });
            this.setPayingStatus(true, this.verifyPaymentRequest);
        }

    };

    renderSuccessMessage = () => {
        return <mobiscroll.Note color="success">
            <div className="mbsc-align-center">
                <p>Your payment has been successful, thank you.</p>
                <p>Payment will appear on your card as <b>{process.env.REACT_APP_MERCHANT_OF_RECORD}</b></p>
                <p>Receipt number: {this.state.payment.id}</p>
            </div>
        </mobiscroll.Note>
    }

    render = () => {

        const inputStyle = "underline"; //mobiscroll.platform.name === "ios" ? "underline" : "outline";
        const labelStyle = "stacked";

        return (
            <mobiscroll.Form
                className="net-form-width-lg"
                inputStyle={inputStyle}
                labelStyle={labelStyle}
            >

                <Header />

                {this.state.paid ?
                    this.renderSuccessMessage()
                    :
                    <React.Fragment>
                        <PayInvoiceByCard
                            inputStyle={inputStyle}
                            labelStyle={labelStyle}
                            merchant={this.props.merchant}
                            handlePayInvoiceFormStateChange={this.handlePayInvoiceFormStateChange}
                        />

                        <div className="mbsc-grid">
                            <div className="mbsc-row">
                                <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4">
                                    <div className="mbsc-btn-group-block">
                                        <mobiscroll.Button onClick={(e) => {
                                                e.preventDefault();
                                                this.payInvoice();
                                            }} disabled={this.state.paying} color="success" style={{color: '#fff'}}>Pay Now</mobiscroll.Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }

                <div className="mbsc-align-center mbsc-txt-s">
                    <p>Payment will appear on your card as <b>{process.env.REACT_APP_MERCHANT_OF_RECORD}</b></p>
                    {!isIframe() ?
                    <p><a href={termsUrl}>Terms &amp; Refund Policy</a> | <a href={privacyUrl}>Privacy Policy</a></p>
                        : null}
                    <p>Secure payments by <a href="https://www.netpayments.com.au" target="_blank" rel="noreferrer">Net Payments</a></p>
                    {this.props.merchant.cards.map((card, idx) => <img key={card} src={"./img/"+card+".png"} alt="" style={{maxHeight: "45px"}} />)}
                </div>
            </mobiscroll.Form>
        );
    }
}

export default withRouter(PayInvoiceByCardFatZebra);