import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import './App.css';
import mobiscroll from "@mobiscroll/react";
import {isIframe, logger, updateObject} from "./lib/Utility";
import {netFirebase} from "./lib/Firebase";
import PayInvoiceByCardFatZebra from "./containers/invoice/providers/PayInvoiceByCardFatZebra";
import PayInvoiceByCardPinPayments from "./containers/invoice/providers/PayInvoiceByCardPinPayments";
import Header from "./components/Header";
import Privacy from "./containers/privacy/Privacy";
import Terms from "./containers/terms/Terms";

const darkMode = false;
let theme;
switch (mobiscroll.platform.name) {
    case 'android':
        theme = darkMode ? 'material-dark' : 'material';
        break;
    case 'windows':
    case 'wp':
        theme = darkMode ? 'windows-dark' : 'windows';
        break;
    case 'ios':
    default:
        theme = darkMode ? 'ios-dark' : 'ios';
}
//logger('theme: '+ theme);
mobiscroll.settings = {
    theme: theme
}

// config to use react router
//mobiscroll.Route = Route;
// setup the React Router with Mobiscroll
mobiscroll.setupReactRouter(Route, withRouter);

class App extends Component {

    state = {
        loading: true,
        merchant: null
    }

    getMerchant = () => {

        if (!netFirebase._initted || netFirebase._firebase_app === null) {
            logger('Firebase not yet initted|'+netFirebase._initted+'|'+netFirebase._firebase_app)
        }
        const getMerchant = netFirebase._firebase_app.functions(process.env.REACT_APP_FIREBASE_REGION).httpsCallable('getMerchant');
        getMerchant({
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            merchantToken: process.env.REACT_APP_MERCHANT_TOKEN
        }).then((result) => {
            logger(result);
            if (result.data.status === "OK") {
                // set this.state.merchant
                const updatedState = updateObject(this.state, {
                    loading: false,
                    merchant: result.data.data
                });
                this.setState(updatedState);
            } else {
                // result.data.status === "ERR"
                const message = result.data.error;
                mobiscroll.toast({message: message, color: 'danger', duration: false});
                const updatedState = updateObject(this.state, {
                    loading: false,
                    merchant: null
                });
                this.setState(updatedState);
            }
        }).catch((error) => {
            logger(error)
            //const code = error.code;
            const message = error.message;
            //const details = error.details;
            //logger(code+"|"+message+"|"+details)
            mobiscroll.toast({message: message, color: 'danger', duration: false});
            const updatedState = updateObject(this.state, {
                loading: false,
                merchant: null
            });
            this.setState(updatedState);
        });

    }

    componentDidMount = () => {
        logger('App componentDidMount');
        //netFirebase.SetProps(this.props);
        this.getMerchant();
    }

    componentDidUpdate = () => {
        logger('App componentDidUpdate');
        //netFirebase.SetProps(this.props);
        //this.getMerchant();
    }

    renderEmpty = (msg) => {
        return <mobiscroll.Form className="net-form-width-lg">
            <Header />
            <div className="mbsc-empty">
                <p>{msg}</p>
            </div>
        </mobiscroll.Form>
    }

    renderPayInvoice = () => {
        const providerName = this.state.merchant !== null ? this.state.merchant.providerName : "";
        //logger(providerName);
        switch (providerName) {
            case "FatZebra":
                return <PayInvoiceByCardFatZebra merchant={this.state.merchant} />
            case "PinPayments":
                return <PayInvoiceByCardPinPayments merchant={this.state.merchant} />
            default:
                return this.renderEmpty('Unrecognised provider');
        }
    }

    render = () => {

        //let headerStyle = {backgroundImage: `url("${headerLogo}")`};
        const windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        const backgroundImage = !isIframe() && windowWidth > 720 && process.env.REACT_APP_BACKGROUND_IMAGE !== null && process.env.REACT_APP_BACKGROUND_IMAGE !== "" ? process.env.REACT_APP_BACKGROUND_IMAGE : null;
        const backgroundImageClass = backgroundImage !== null ? "background-image" : null;
        const backgroundImageStyle = backgroundImage !== null ? {backgroundImage: `url("${backgroundImage}")`} : null;

        return (
            <mobiscroll.Page className="app-page">
                <div className={backgroundImageClass} style={backgroundImageStyle}>
                    <div className="app-tab">
                    {this.state.loading ?
                        this.renderEmpty('Loading secure payment platform...')
                    : this.state.merchant === null ?
                        this.renderEmpty('Merchant not found or not defined')
                    :
                        <Switch>
                            {/*
                            <Route path="/" exact={true} render={this.renderEmpty('[Secure Payment Platform]')} />
                            */}
                            <Route path="/invoice/:currency/:amount/:invnum/:client" render={this.renderPayInvoice} />
                            <Route path="/invoice/:currency/:amount/:invnum" render={this.renderPayInvoice} />
                            <Route path="/invoice/:currency/:amount" render={this.renderPayInvoice} />
                            <Route path="/invoice" exact={true} render={this.renderPayInvoice} />
                            <Route path="/pos" exact={true} render={this.renderPayInvoice} />
                            <Route path="/privacy" component={Privacy} />
                            <Route path="/terms" component={Terms} />
                            <Redirect from="*" to="/invoice"/>
                        </Switch>
                    }
                    </div>
                </div>
            </mobiscroll.Page>
        );

    }

}

export default withRouter( App );