import React from 'react';
import mobiscroll from "@mobiscroll/react";
import { withRouter } from 'react-router-dom';
import CardPaymentForm from "../../components/card/CardPaymentForm";
import {updateObject} from "../../lib/Utility";

class PayInvoiceByCard extends React.Component {

    constructor (props) {
        super(props);
        //logger(props);

        const currency = "AUD"; //props.hasOwnProperty('match') && props.match.params.hasOwnProperty('currency') ? props.match.params.currency : "AUD";
        const amount = props.hasOwnProperty('match') && props.match.params.hasOwnProperty('amount') && !isNaN(props.match.params.amount) ? parseFloat(props.match.params.amount).toFixed(2) : "";
        const invnum = props.hasOwnProperty('match') && props.match.params.hasOwnProperty('invnum') ? props.match.params.invnum : "";
        const client = props.hasOwnProperty('match') && props.match.params.hasOwnProperty('client') ? props.match.params.client : "";
        const email = ""; //props.hasOwnProperty('match') && props.match.params.hasOwnProperty('email') ? props.match.params.email : "";
        const isPOS = props.hasOwnProperty('match') && props.match.url.toLowerCase() === "/pos";

        const amountEditable = amount === "" || amount === "0" || amount === "0.00";
        const invnumEditable = invnum === "";
        const clientEditable = client === "";

        // todo: validate currency in this.props.merchant.currencies

        const surchargeAmount = this.props.merchant.surcharge ? this.calculateSurcharge(amount) : 0;
        const totalAmount = !isNaN(amount) && parseFloat(amount) > 0 ? parseFloat(amount)+surchargeAmount : 0;

        this.state = {
            isPOS: isPOS,
            amount: amount,
            amountError: "",
            currency: currency,
            client: client,
            clientError: "",
            invnum: invnum,
            invnumError: "",
            email: email,
            emailError: "",
            amountEditable: amountEditable,
            invnumEditable: invnumEditable,
            clientEditable: clientEditable,
            emailEditable: true,
            surcharge: this.props.merchant.surcharge,
            surchargeAmount: surchargeAmount,
            totalAmount: totalAmount,
            card: {}, // from CardPaymentForm
        };

        this.handleCardPaymentFormStateChange = this.handleCardPaymentFormStateChange.bind(this);
        this.handlePayInvoiceFormStateChange = this.handlePayInvoiceFormStateChange.bind(this);
    }

    handlePayInvoiceFormStateChange = () => {
        if (this.props.handlePayInvoiceFormStateChange !== undefined && typeof(this.props.handlePayInvoiceFormStateChange) === 'function') {
            this.props.handlePayInvoiceFormStateChange(this.state);
        }
    }

    handleCardPaymentFormStateChange = (_State) => {
        const updatedState = updateObject(this.state, {
            card: _State
        });
        this.setState(updatedState, this.handlePayInvoiceFormStateChange);
    }

    handleInputFocus = (e) => {
        const fieldName = e.target.getAttribute('data-fieldname');
        this.setState({ focus: fieldName });
    }

    handleInputChange = (e) => {
        const fieldName = e.target.getAttribute('data-fieldname');
        const fieldValue = e.target.value;
        this.setState({ [fieldName]: fieldValue }, this.handlePayInvoiceFormStateChange);
    }

    calculateSurcharge = (amount) => {
        let surcharge = 0;
        if (amount !== undefined && !isNaN(amount) && parseFloat(amount) > 0) {
            const costFixed = this.props.merchant.hasOwnProperty('costFixed') && !isNaN(this.props.merchant.costFixed) && this.props.merchant.costFixed > 0 ? this.props.merchant.costFixed : 0;
            const costPercent = this.props.merchant.hasOwnProperty('costPercent') && !isNaN(this.props.merchant.costPercent) && this.props.merchant.costPercent > 0 ? this.props.merchant.costPercent / 100 : 0;

            if (costPercent > 0) {
                amount = parseFloat(amount);
                surcharge = amount * costPercent;
                surcharge += surcharge * costPercent;
                surcharge += costFixed;
                surcharge = parseFloat(surcharge.toFixed(2));
                //logger("surcharge1 "+surcharge);

                let total = amount+surcharge;
                let nett = total-((total*costPercent)+costFixed);
                nett = parseFloat(nett.toFixed(2));
                //logger("nett "+nett);

                while (nett < amount) {
                    surcharge += 0.01;
                    total = amount+surcharge;
                    nett = total-((total*costPercent)+costFixed);
                    nett = parseFloat(nett.toFixed(2));
                    //logger("nett "+nett);
                }
                //logger("surcharge "+surcharge);
            } else if (costFixed > 0) {
                surcharge = costFixed;
            }
        }
        return surcharge;
    }

    handleAmountChange = (e) => {
        const amount = e.target.value;
        const surchargeAmount = this.state.surcharge ? this.calculateSurcharge(amount) : 0;
        const totalAmount = !isNaN(amount) && parseFloat(amount) > 0 ? parseFloat(amount)+surchargeAmount : 0;
        this.setState({
            amount: amount,
            surchargeAmount: surchargeAmount,
            totalAmount: totalAmount
        }, this.handlePayInvoiceFormStateChange);
    }

    setSurcharge = (e) => {
        const surcharge = e.target.checked;
        const amount = this.state.amount;
        const surchargeAmount = surcharge ? this.calculateSurcharge(amount) : 0;
        const totalAmount = !isNaN(amount) && parseFloat(amount) > 0 ? parseFloat(amount)+surchargeAmount : 0;
        this.setState({
            surchargeAmount: surchargeAmount,
            totalAmount: totalAmount,
            surcharge: surcharge
        });
    }

    render = () => {
        return (
            <React.Fragment>
                <div className="mbsc-grid">
                    {(process.env.REACT_APP_CLIENT_REFERENCE_ENABLED && process.env.REACT_APP_CLIENT_REFERENCE_ENABLED === 'true') || (process.env.REACT_APP_INVOICE_REFERENCE_ENABLED && process.env.REACT_APP_INVOICE_REFERENCE_ENABLED === 'true') ?
                    <div className="mbsc-row">
                        {process.env.REACT_APP_CLIENT_REFERENCE_ENABLED && process.env.REACT_APP_CLIENT_REFERENCE_ENABLED === 'true' ?
                            <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-lg-3">
                                <mobiscroll.Input
                                    inputStyle={this.props.inputStyle}
                                    labelStyle={this.props.labelStyle}
                                    type="text"
                                    name="client"
                                    //placeholder={process.env.REACT_APP_CLIENT_REFERENCE_LABEL}
                                    data-fieldname="client"
                                    maxLength={20}
                                    value={this.state.client}
                                    valid={this.state.clientError === ""}
                                    errorMessage={this.state.clientError}
                                    disabled={!this.state.clientEditable}
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                >{process.env.REACT_APP_CLIENT_REFERENCE_LABEL}</mobiscroll.Input>
                            </div>
                            : null}

                        {process.env.REACT_APP_INVOICE_REFERENCE_ENABLED && process.env.REACT_APP_INVOICE_REFERENCE_ENABLED === 'true' ?
                            <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-lg-3">
                                <mobiscroll.Input
                                    inputStyle={this.props.inputStyle}
                                    labelStyle={this.props.labelStyle}
                                    type="text"
                                    name="invnum"
                                    //placeholder={process.env.REACT_APP_INVOICE_REFERENCE_LABEL}
                                    data-fieldname="invnum"
                                    maxLength={20}
                                    value={this.state.invnum}
                                    valid={this.state.invnumError === ""}
                                    errorMessage={this.state.invnumError}
                                    disabled={!this.state.invnumEditable}
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                >{process.env.REACT_APP_INVOICE_REFERENCE_LABEL}</mobiscroll.Input>
                            </div>
                            : null}
                    </div>
                    : null}

                    <div className="mbsc-row">

                        <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-lg-3">
                            <mobiscroll.Input
                                inputStyle={this.props.inputStyle}
                                labelStyle={this.props.labelStyle}
                                type="number"
                                name="transaction-amount"
                                autoComplete="transaction-amount"
                                placeholder="0.00"
                                data-fieldname="amount"
                                maxLength={20}
                                value={this.state.amount}
                                valid={this.state.amountError === ""}
                                errorMessage={this.state.amountError}
                                disabled={!this.state.amountEditable}
                                onChange={this.handleAmountChange}
                                onFocus={this.handleInputFocus}
                            >Amount to Pay</mobiscroll.Input>
                        </div>

                        {this.state.isPOS ?
                        <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-lg-3">
                            <mobiscroll.Switch checked={this.state.surcharge} onChange={this.setSurcharge} color="primary" labelStyle={this.props.labelStyle} inputStyle={this.props.labelStyle}>
                                <span className="mbsc-txt-s">Surcharge</span>
                            </mobiscroll.Switch>
                        </div>
                            : null}

                        {/*
                        <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6">
                            <div style={{marginLeft: "1.5em"}} >
                                <span className="mbsc-txt-s">Payable by:<br /></span>
                                {this.props.merchant.cards.map((card, idx) => <img src={"./img/"+card+".png"} style={{maxHeight: "45px"}} />)}
                            </div>
                        </div>
                        */}
                    </div>
                </div>

                <React.Fragment>
                    <CardPaymentForm
                        inputStyle={this.props.inputStyle}
                        labelStyle={this.props.labelStyle}
                        merchant={this.props.merchant}
                        handleCardPaymentFormStateChange={this.handleCardPaymentFormStateChange}
                    />
                </React.Fragment>

                <div className="mbsc-grid">
                    {this.props.merchant.emailAddressRequired ?
                    <div className="mbsc-row">
                        <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6">
                            <mobiscroll.Input
                                inputStyle={this.props.inputStyle}
                                labelStyle={this.props.labelStyle}
                                type="email"
                                name="email"
                                //placeholder={process.env.REACT_APP_INVOICE_REFERENCE_LABEL}
                                data-fieldname="email"
                                maxLength={255}
                                value={this.state.email}
                                valid={this.state.emailError === ""}
                                errorMessage={this.state.emailError}
                                disabled={!this.state.emailEditable}
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            >{this.state.isPOS ? "Cardholder Email Address" : "Email Address" }</mobiscroll.Input>
                        </div>
                    </div>
                        : null }

                    {/*this.props.merchant.billingAddressRequired ?
                        <div className="mbsc-row">
                            <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6">
                                <mobiscroll.Input
                                    inputStyle={this.props.inputStyle}
                                    labelStyle={this.props.labelStyle}
                                    type="text"
                                    name="billing_address1"
                                    data-fieldname="billing_address1"
                                    maxLength={255}
                                    value={this.state.billing_address1}
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                >Billing Address</mobiscroll.Input>
                            </div>
                        </div>
                        : null */}

                    {this.state.surcharge ?
                    <React.Fragment>
                        {/*
                        <div className="mbsc-row">
                            <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6">
                                <div className="mbsc-padding" style={{paddingBottom: 0}}>
                                    <span className="mbsc-txt-s">Sub total</span>
                                    <span className="mbsc-txt-s mbsc-pull-right">{parseFloat(this.state.amount).toFixed(2)} {this.state.currency.toUpperCase()}</span>
                                </div>
                            </div>
                        </div>
                        */}
                        <div className="mbsc-row">
                            <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6">
                                <div className="mbsc-padding" style={{paddingBottom: 0}}>
                                    <span className="mbsc-txt-s">Card surcharge</span>
                                    <span className="mbsc-txt-s mbsc-pull-right">{parseFloat(this.state.surchargeAmount).toFixed(2)} {this.state.currency.toUpperCase()}</span>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    : null }
                    <div className="mbsc-row">
                        <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6">
                            <div className="mbsc-padding" style={{paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Total payment</span>
                                <span className="mbsc-txt-s mbsc-pull-right">{parseFloat(this.state.totalAmount).toFixed(2)} {this.state.currency.toUpperCase()}</span>
                            </div>
                        </div>
                    </div>

                </div>

            </React.Fragment>
        );
    }
}

export default withRouter(PayInvoiceByCard);